<template>
  <div class="view pa24">
    <el-button class="mr10"
               type="primary" @click="addNullPage"
    >添加空白码</el-button>
    <commonTable :tableData="tableData" :total="total">
      <template v-slot:table>
        <el-table-column align="center" type="index" label="序号" />
        <el-table-column prop="buyUser" align="center" label="购买人"/>
        <el-table-column label="激活/总数量" prop="num"  align="center">
          <template slot-scope="scope">
             {{scope.row.activeNum}}/{{scope.row.num}}
          </template>
        </el-table-column>
        <el-table-column label="价格" prop="price"  align="center"/>
        <el-table-column label="备注" prop="remark"  align="center"/>
        <el-table-column label="创建时间" prop="createTime"  align="center"/>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="seeDetail(scope.row.id)">查看</el-button>
          </template>
        </el-table-column>
      </template>
    </commonTable>
    <el-dialog :visible.sync="detailDialog" title="查看" append-to-body>
      <commonTable :tableData="detailData.list"  :total="total">
        <template v-slot:table>
          <el-table-column align="center" type="index" label="序号" />
          <el-table-column prop="handle" align="center" label="标识"/>
          <el-table-column label="密码" prop="password"  align="center"/>
          <el-table-column label="页面标题" prop="title"  align="center"/>
          <el-table-column label="创建时间" prop="createTime"  align="center"/>
          <el-table-column label="激活人" align="center">
            <template slot-scope="scope">
              <el-avatar shape="square" size="20" :src="scope.row.avatarUrl"/>
            </template>
          </el-table-column>
          <el-table-column label="激活人昵称" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.nickName}}</span>
            </template>
          </el-table-column>
          <el-table-column label="激活时间" prop="activeTime" align="center"/>
        </template>
      </commonTable>
    </el-dialog>
    <el-dialog title="添加空白码"
               :visible.sync="centerDialogVisible"
               :modal-append-to-body="false"
               :destroy-on-close="true"
               :close-on-click-modal="false"
               width="30%"
               center>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
        <el-form-item label="购买方" prop="buyUser">
          <el-input v-model="ruleForm.buyUser"></el-input>
        </el-form-item>
        <el-form-item label="购买价格" prop="price">
          <el-input v-model="ruleForm.price" type="number"></el-input>
        </el-form-item>
        <el-form-item label="数量" prop="num">
          <el-input v-model="ruleForm.num" type="number"></el-input>
        </el-form-item>
        <el-form-item label="需要密码">
          <el-radio-group v-model="ruleForm.requirePass">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="随机密码" v-if="ruleForm.requirePass">
          <el-radio-group v-model="ruleForm.randomPass" >
            <el-radio :label="true">随机</el-radio>
            <el-radio :label="false">不随机</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="密码" prop="password" v-if="ruleForm.requirePass&&!ruleForm.randomPass">
          <el-input v-model="ruleForm.password" placeholder="请输入六位数密码" type="password"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="ruleForm.remark"></el-input>
        </el-form-item>
        <el-form-item label="绑定模板" prop="modelList">
          <el-checkbox-group v-model="ruleForm.modelList">
            <el-checkbox :label="item.id" v-for="item in modelList" :key="item.id">{{item.title}}</el-checkbox>
            <el-checkbox>sda</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
				<el-button @click="centerDialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="addPage">确 定</el-button>
			</span>
    </el-dialog>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import {get, post} from "@/utils/request";
export default {
  data() {
    return {
      centerDialogVisible: false,
      detailDialog: false,
      pageSize: 10,
      curPage: 1,
      total: 0,
      tableData: [],
      ruleForm: {requirePass: false,randomPass: true,modelList:[]},
      detailParam: {pageNum:1,pageSize:10},
      detailData: {},
      rules: {
        num: [{
          required: true,
          message: "请输入数量",
          trigger: "blur"
        }]
      },
      modelList:[]
    };
  },
  components: {
    commonTable
  },
  computed: {
  },
  mounted() {
    this.queryPage();
  },
  methods: {
     queryPage() {
      let params = {
        pageSize: this.pageSize,
        pageNum: this.curPage
      };
      get('/ryt/batch',params).then(res=>{
         this.total=res.total
         this.tableData=res.list
      })
    },
    initModelList(){
      get('/ryt/model',{type:0,pageNum: 0}).then(res=>{

         this.modelList=res.list
        console.log('模板列表',this.modelList)
      })
    },
    addPage(){
      post('/ryt/batchPage',this.ruleForm).then(res=>{
        this.centerDialogVisible=false;
        this.queryPage()
      })
    },
    initDetail(){
      get('/ryt/batchDetail',this.detailParam).then(res=>{
          this.detailData=res
      })
    },
    addNullPage(){
       this.initModelList();
      this.centerDialogVisible=true;
    },
    seeDetail(id){
       this.detailParam.pageNum=1;
       this.detailParam.id=id;
       this.detailDialog=true;
       this.initDetail()
    }
  },
};
</script>